<script lang="ts" setup>
import {getServiceBlogId} from '@/api/blog';
import { ref } from 'vue';
import SimpleModal from '../control/simpleModal.vue';
import { createContactUs } from '@/api/request';
import type { ContactUs } from '@/model/model';
import { useToast } from 'vue-toastification';
import { useWeb3Store } from '@/stores/modules/web3';

const web3Store = useWeb3Store()

const showContactUs = ref(false)
const captcha_url = ref('/api/1/rec-server/client/captcha?r=' + Math.random())
const contactUsData = ref({
  subject: '',
  content: '',
  email: '',
  tel: '',
  captcha: ''
})
const contactUsError = ref({
  subject: false,
  content: false,
  email: false,
  captcha: false,
})

const refreshCaptcha = () => {
  captcha_url.value = '/api/1/rec-server/client/captcha?r=' + Math.random()
}

async function sendContactUs() {
  let success = true
  if(contactUsData.value.subject.length == 0){
    contactUsError.value.subject = true
    success = false
  } else {
    contactUsError.value.subject = false
  }
  if(contactUsData.value.content.length == 0){
    contactUsError.value.content = true
    success = false
  } else {
    contactUsError.value.content = false
  }
  if(contactUsData.value.email.length == 0){
    contactUsError.value.email = true
    success = false
  } else {
    contactUsError.value.email = false
  }
  if(contactUsData.value.captcha.length == 0){
    contactUsError.value.captcha = true
    success = false
  } else {
    contactUsError.value.captcha = false
  }
  let emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/
  let pass = emailReg.test(contactUsData.value.email)
  if(!pass){
    contactUsError.value.email = true
    success = false
  } else {
    contactUsError.value.email = false
  }
  if(!success){
    return
  }
  const resp = await createContactUs({
    subject: contactUsData.value.subject,
    content: contactUsData.value.content,
    email: contactUsData.value.email,
    tel: contactUsData.value.tel,
    captcha: contactUsData.value.captcha
  } as ContactUs)
  console.log(resp)
  if(resp.code == 200){
    const toast = useToast()
    toast.success("Send success")
    contactUsData.value.content = ''
    contactUsData.value.email = ''
    contactUsData.value.subject = ''
    contactUsData.value.tel = ''
    showContactUs.value = false
  } else {
    const toast = useToast()
    toast.error(resp.message)
  }
}

function goToSubPage(url:string){
  window.location.href = url
}

async function gotoServcie(name: string){
  const resp = await getServiceBlogId(name)
  if(resp.code == 200){
    window.location.href = '/blog?id=' + resp.data
  }
}
</script>

<template>
  <footer class="px-2 w-full lg:px-16 bg-darkBackground">
    <div class="mx-auto xl:container xl:max-w-7xl">
      <div class="flex flex-wrap justify-between justify-items-stretch pt-12 text-white">
        <div class="flex-auto px-2 w-full text-xl lg:flex-1 md:w-screen">
          Grex is a truly transparent marketplace to buy, sell, and discover different types of REC and carbon credit.
        </div>
        <div class="grid justify-items-start px-2 mt-7 w-full sm:justify-items-center md:w-auto md:mt-3 md:flex-auto md:grid-cols-3 lg:gap-x-4 lg:mt-4 xl:mt-0">
          <div class="block">
            <div class="text-xl font-bold">Marketplace</div>
            <button class="mt-4 text-base hover:underline" @click="goToSubPage('/rec/list')">All RECs</button>
            <div></div>
            <button class="mt-4 text-base hover:underline" @click="goToSubPage('/carbon/list')">All Carbon Credits</button>
            <div></div>
            <button v-if="web3Store.enable" class="mt-4 text-base hover:underline" @click="goToSubPage('/nft/market')">All REC NFTs</button>
          </div>
          <div class="block mt-7 md:mt-0">
            <div class="text-xl font-bold">Resources</div>
            <button class="mt-4 text-base hover:underline" @click="goToSubPage('/blogs?category=announcement')">Announcements</button>
            <div class="text-base"></div>
            <button class="mt-4 text-base hover:underline" @click="goToSubPage('/blogs?category=resource')">Guides</button>
            <div class="text-base"></div>
            <button class="mt-4 text-base hover:underline" @click="goToSubPage('/helpcenter/faqs')">FAQs</button>
          </div>
          <div class="block mt-7 md:mt-0">
            <div class="text-xl font-bold">Company</div>
            <button class="mt-4 text-base hover:underline" @click="goToSubPage('/about')">About</button>
            <div class="text-base"></div>
            <button class="mt-4 text-base hover:underline" @click="showContactUs=true">Contact Us</button>
          </div>
         
        </div> 
        <div class="flex justify-start items-center mt-4 md:mt-0">
          <img class="object-fill w-20 md:w-32" src="/images/logo-05.png" />
        </div>
      </div>
      <div class="py-6 divide-y divide-white sm:py-12">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
      <div class="flex relative flex-wrap items-center pb-12 text-white">
        <div class="w-full text-center md:w-auto">
          © {{new Date().getFullYear()}} Grex.co
        </div>
        <div class="hidden text-xs text-center md:flex-1 md:block">Power by  SymphonyProtocol Labs</div>
        <button class="mt-4 w-1/2 text-center md:mt-0 md:w-auto" @click="gotoServcie('Privacy Policy')">
          Privacy Policy
        </button>
        <button class="mt-4 w-1/2 text-center md:mt-0 md:w-auto md:ml-7" @click="gotoServcie('Terms of Service')">
          Terms of Service
        </button>
      </div>
    </div>
    <SimpleModal :open="showContactUs" title="Contact US" @close="showContactUs = false" success-buttion-text="Send" @confirm="sendContactUs">
      <template v-slot:content>
        <div class="block p-4 w-96 max-w-xl">
          <label class="block">
            <span class="block">Subject</span>
            <span class="text-xs text-red-500" v-if="contactUsError.subject">*Subject is required</span>
            <input class="mt-2 w-full rounded" type="text" v-model="contactUsData.subject"/>
          </label>
          <label class="block mt-4">
            <span class="block">Content</span>
            <span class="text-xs text-red-500" v-if="contactUsError.content">*Content is required</span>
            <textarea class="mt-2 w-full rounded" v-model="contactUsData.content"></textarea>
          </label>
          <label class="block mt-4">
            <span class="block">Email</span>
            <span class="text-red-500 text-" v-if="contactUsError.email">*Email is required</span>
            <input class="mt-2 w-full rounded" type="email" v-model="contactUsData.email"/>
          </label>
          <label class="block mt-4">
            <span class="block">Tel - Optional</span>
            <input class="mt-2 w-full rounded" type="text" v-model="contactUsData.tel"/>
          </label>
          <label class="flex justify-between items-center mt-4 w-full">
            <div class="flex gap-x-2 items-center w-1/2">
              <img :src="captcha_url" alt="recaptcha" />
              <button class="text-mainGreen" @click="refreshCaptcha">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
            </div>
            <input type="text" v-model="contactUsData.captcha" class="w-36 rounded" placeholder=""/>
          </label>
          <span class="text-red-500 text-" v-if="contactUsError.captcha">*Captcha is required</span>
        </div>
      </template>
    </SimpleModal>
  </footer>
</template>
